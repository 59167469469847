<template>
  <ul class="w-full rounded-b pl-[9px] py-[2px]" :class="props.bgColorClass">
    <li
      class="text-sm leading-xs"
      :class="[props.classes, props.textColorClass]"
    >
      {{ props.message }}
    </li>
  </ul>
</template>

<script setup lang="ts">
const props = withDefaults(defineProps<Props>(), {
  message: 'customMessage',
  bgColorClass: 'bg-[#ffffff99]',
  textColorClass: 'text-sign-red',
  classes: '',
});

interface Props {
  message: string;
  bgColorClass?: string;
  textColorClass?: string;
  classes?: string;
}
</script>
